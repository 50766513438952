<!--
 * @Author: ttheitao
 * @Description: 演示多功能合并导出PDF
 * @Date: 2022-10-13 10:45:12
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-10-14 09:23:51
-->
<template>
    <div class="demo-pdf-merge" v-loading="isExport || isMerge">
        <header class="header">
            <div class="search">
                <el-input
                    v-model="searchKey"
                    placeholder="搜索功能名称"
                ></el-input>
            </div>
            <div class="actions">
                <el-button type="primary" @click="mergeExport">
                    一键导出
                </el-button>
            </div>
        </header>
        <el-table
            :data="tableData"
            ref="multipleTable"
            stripe
            style="width: 100%"
        >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="name" label="功能名称"></el-table-column>
            <el-table-column prop="user" label="负责人"> </el-table-column>
            <el-table-column prop="date" label="创建日期"> </el-table-column>
            <el-table-column width="150" label="操作">
                <template slot-scope="scope">
                    <span @click="handlePreview(scope.row)">预览</span> |
                    <span @click="handleExport(scope.row)"> 导出 </span>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog
            custom-class="pdf-merge-dialog"
            :visible.sync="showEntry"
            fullscreen
        >
            <Entry
                v-if="showEntry"
                loadType="create"
                :type="oneExcel.type"
                :solveType="oneExcel.solveType"
                :excelUuid="oneExcel.excelUuid"
                :objectUuid="oneExcel.objectUuid"
                :viewUuid="oneExcel.viewUuid"
                :dataIds="oneExcel.dataIds"
                :elementId="oneExcel.elementId"
            ></Entry>
        </el-dialog>

        <Entry
            v-if="isExport"
            loadType="preload"
            :type="oneExcel.type"
            :solveType="oneExcel.solveType"
            :excelUuid="oneExcel.excelUuid"
            :objectUuid="oneExcel.objectUuid"
            :viewUuid="oneExcel.viewUuid"
            :dataIds="oneExcel.dataIds"
            :elementId="oneExcel.elementId"
            exportType="pdf"
            @exportSuccess="exportSuccess"
        ></Entry>

        <div v-if="isMerge">
            <Entry
                v-for="(item, index) in list"
                :key="index"
                loadType="create"
                type="export"
                solveType="form"
                :excelUuid="item.sheetUuid"
                :objectUuid="item.objectUuid"
                :viewUuid="item.viewUuid"
                :dataIds="item.type === 'list' ? 'all' : item.id"
                :elementId="guid()"
                exportType="pdf:url"
                @exportSuccess="mregeExportSuccess"
            ></Entry>
        </div>
    </div>
</template>

<script>
import Entry from "@/custom-component/common/Entry.vue";
import { Dialog } from "element-ui";
import { axios } from "@/apis/http/request";

export default {
    components: {
        Entry,
        "el-dialog": Dialog,
    },
    data() {
        return {
            searchKey: "",
            tableData: [
                {
                    date: "2022-10-10",
                    user: "王五",
                    name: "质量整改通知单",
                    objectUuid: "object6268b4c9c083f",
                    viewUuid: "view62f9a012be0ad",
                    sheetUuid: "sheets63477518de74f",
                    type: "info",
                    id: 139,
                },
                {
                    date: "2022-09-10",
                    user: "范湖",
                    name: "项目监理机构人员任命书",
                    objectUuid: "object6347766621812",
                    viewUuid: "view634784f23c559",
                    sheetUuid: "sheets6347c72f02a08",
                    type: "info",
                    id: 2,
                },
                {
                    date: "2022-09-10",
                    user: "范湖",
                    name: "安全生产费用使用审查登记表",
                    objectUuid: "object62f612d5c49f3",
                    viewUuid: "view62f9b85db5725",
                    sheetUuid: "sheets6347c92863fb7",
                    type: "info",
                    id: 39,
                },
                {
                    date: "2022-09-10",
                    user: "范湖",
                    name: "安全巡检-详情",
                    objectUuid: "object61c42817554f6",
                    viewUuid: "view61ca81a11ac70",
                    sheetUuid: "sheets6347c1c0bf7da",
                    type: "info",
                    id: 43,
                },
                {
                    date: "2022-09-10",
                    user: "范湖",
                    name: "勘察文件质量检查报告",
                    objectUuid: "object632c1820c58c5",
                    viewUuid: "view632c2632caf16",
                    sheetUuid: "sheets6347c1ca4c86f",
                    type: "info",
                    id: 6,
                },
            ],
            showEntry: false,
            oneExcel: {
                type: "",
                excelUuid: "",
                objectUuid: "",
                viewUuid: "",
                elementId: "",
                solveType: "",
                dataIds: "",
            },
            isExport: false,
            isMerge: false,
            count: 0,
            list: [],
            pdf: [],
        };
    },
    methods: {
        mergeExport() {
            this.count = this.$refs.multipleTable.selection.length;

            if (this.count) {
                this.pdf = [];
                this.isMerge = true;
                this.list = this.$refs.multipleTable.selection;
            }
        },
        handlePreview(row) {
            this.oneExcel.type = "info";
            this.oneExcel.excelUuid = row.sheetUuid;
            this.oneExcel.objectUuid = row.objectUuid;
            this.oneExcel.viewUuid = row.viewUuid;
            this.oneExcel.solveType = row.type === "list" ? "table" : "form";
            this.oneExcel.dataIds = row.type === "list" ? "all" : row.id;
            this.oneExcel.elementId = "PdfMergeInfoId";
            this.showEntry = true;
        },
        handleExport(row) {
            this.oneExcel.type = "export";
            this.oneExcel.excelUuid = row.sheetUuid;
            this.oneExcel.objectUuid = row.objectUuid;
            this.oneExcel.viewUuid = row.viewUuid;
            this.oneExcel.solveType = row.type === "list" ? "table" : "form";
            this.oneExcel.dataIds = row.type === "list" ? "all" : row.id;
            this.oneExcel.elementId = "PdfMergeExportId";
            this.isExport = true;
        },
        exportSuccess() {
            this.isExport = false;
        },
        guid() {
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
                /[xy]/g,
                function (c) {
                    var r = (Math.random() * 16) | 0,
                        v = c == "x" ? r : (r & 0x3) | 0x8;
                    return v.toString(16);
                }
            );
        },
        mregeExportSuccess(res) {
            this.count--;
            this.pdf.push(res.url);

            if (this.count < 1) {
                axios
                    .request({
                        url: "api/mapi",
                        data: {
                            __method_name__: "imageToPdfFile",
                            image_path: this.pdf,
                        },
                        method: "POST",
                        responseType: "blob",
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            const blob = new Blob([res.data]);
                            const downloadElement = document.createElement("a");
                            const href = window.URL.createObjectURL(blob); //创建下载的链接
                            downloadElement.href = href;
                            downloadElement.download = "下载数据.pdf"; //下载后文件名
                            document.body.appendChild(downloadElement);
                            downloadElement.click(); //点击下载
                            document.body.removeChild(downloadElement); //下载完成移除元素
                            window.URL.revokeObjectURL(href); //释放掉blob对象
                        }
                        this.isMerge = false;
                    })
                    .catch((err) => {
                        console.log(err, "-----");
                        this.isMerge = false;
                    });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.demo-pdf-merge {
    padding: 20px;

    .header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
    }
}
</style>

<style lang="less">
.pdf-merge-dialog {
    display: flex;
    flex-direction: column;
    .el-dialog__body {
        flex: 1;
    }
}
</style>